export const options1 = [
    {
      label: '全部',
      value: '1',
    },
    {
      label: '未开票',
      value: '2',
    },
    {
      label: '已开票',
      value: '3',
    },
  ];

  export const options2 = [
    {
      label: '全部',
      value: '1',
    },
    {
      label: '饿了么',
      value: '2',
    },
    {
      label: '飞猪',
      value: '3',
    },
    {
        label: '全能超市',
        value: '4',
      },
      {
        label: '阿里公益',
        value: '5',
      },
      {
        label: '高德',
        value: '6',
      },
  ]

  export const options3 = [
    {
      label: '余额支付',
      value: '1',
    },
    {
      label: '银行卡',
      value: '2',
    },
    {
      label: '支付宝',
      value: '3',
    },
    {
        label: '微信',
        value: '4',
      },
  ]
  
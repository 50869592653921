import React, { useState, useCallback, useEffect } from "react";
import { useRequest } from "ahooks";
import * as dd from "dingtalk-jsapi";
// import { InfiniteScroll, Empty, SpinLoading } from "antd-mobile";
import MetroCard from "./Component/MetroCard";
// import Activity from "./Component/Activity";
import PageLoading from "src/common/Component/PageLoading";
import { getBankCard } from "./Services";
import style from "./index.module.scss";

function Wallet() {
  // const { run, data, loading } = useRequest(getBankCard, { manual: true });

  useEffect(() => {
    try {
      dd.env.platform !== 'notInDingTalk' && 
      dd.ready(() => {
        dd.biz.navigation.setTitle({
          title: '钱包'
        });
      });  
    } catch(e) {

    }
    // run({ buyerId: 1 });
  }, []);

  // if (loading) {
  //   return <PageLoading />;
  // }

  const data = {
    onePay: {
      methodName: '余额支付',
    },
    bankCard: {
      methodName: '银行卡'
    },
  };

  return (
    <div className={style.app}>
      <MetroCard detialRoute="/oneCard" data={data?.onePay} />
      <MetroCard detialRoute="/bankCardDetail" data={data?.bankCard} />
    </div>
  );
}

export default Wallet;

import {
  Ellipsis,
  Grid,
  Image,
  InfiniteScroll,
  SpinLoading,
  Swiper,
} from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import get from "lodash/get";
import { useRequest } from "ahooks";

import Page, { PageItem } from "src/common/Component/Page";
import Tabs from "src/common/Component/Tabs";
import ToTop from "src/common/Component/ToTop";
import Search from "src/common/Component/Search";

import StoreCard from "./components/StoreCard";
import { bannerList, getStoreCategory } from "./static";
import { getAddress, IAddressResponse } from "src/common/Utils/getAddress";
import usePageTitle from "src/hooks/usePageTitle";
import { getMaterial } from "../Mall/service/getMaterial";
import styles from "./index.module.scss";

interface IArriveStoreProps {}

const pageSize = 20;

/** 到店-首页  */
const ArriveStore: React.FC<IArriveStoreProps> = (props) => {
  usePageTitle({ title: "到店" });
  const [activeKey, setActiveKey] = useState<string>("1");
  const [address, setAddress] = useState<IAddressResponse>();
  const [queryStr, setQueryStr] = useState("");
  const [storeCategory, setStoreCategory] = useState<
    ReturnType<typeof getStoreCategory>
  >([]);
  // 是否吸顶
  const [hasToTop, setHasToTop] = useState(false);
  const tabRef = useRef<HTMLDivElement>(null);
  const pageNo = useRef(0);
  const hasInit = useRef(true);
  const [allMapData, setAllMapData] = useState<any[]>([]);
  const [hasError, setError] = useState(false);

  const {
    data: materialData,
    run: getMaterialRun,
    loading: materialLoading,
    cancel: cancelMaterialRun,
  } = useRequest(getMaterial, {
    manual: true,
    onSuccess: (res, params) => {
      pageNo.current = params[0].pageNum;
      const list = get(
        res,
        "tbk_dg_material_optional_upgrade_response.result_list.map_data",
        []
      );
      setAllMapData([...allMapData, ...list]);
      setError(false);
    },
    onError: () => {
      setError(true);
      console.log("error");
    },
  });
  const items = bannerList.map((item, index) => (
    <Swiper.Item key={index}>
      <Image
        src={item.imgUrl}
        fit="cover"
        lazy
        width={"100%"}
        height={"100%"}
        className={styles.swiperItem}
        onClick={() => {
          if (item.linkUrl) window.location.href = item.linkUrl;
        }}
      />
    </Swiper.Item>
  ));

  /**
   *  一级类目切换
   */
  const changeTab = (key: string) => {
    console.log("key", key);
    hasInit.current = true;
    pageNo.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    setActiveKey(key);
    getMaterialRun({
      pageNum: pageNo.current + 1,
      pageSize,
      cat: key,
      q: queryStr,
    });
  };

  /** 加载更多 */
  async function loadMore() {
    if (materialLoading || hasError) {
      return;
    }
    if (hasInit.current) {
      hasInit.current = false;
    }
    getMaterialRun({
      pageNum: pageNo.current + 1,
      pageSize,
      cat: activeKey,
      q: queryStr,
    });
  }

  const hasMore = useMemo(() => {
    const total = get(
      materialData,
      "tbk_dg_material_optional_upgrade_response.total_results",
      0
    );
    if (hasInit.current) {
      hasInit.current = false;
      return true;
    }

    return total > allMapData.length;
  }, [materialData, allMapData]);

  const showData = useMemo(() => {
    const data: Array<typeof allMapData> = [[], []];
    allMapData.forEach((item, index) => {
      data[index % 2].push(item);
    });
    return data;
  }, [allMapData]);

  /**
   * 搜索
   */
  const onSearch = (value: string) => {
    hasInit.current = true;
    pageNo.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    getMaterialRun({
      pageNum: pageNo.current + 1,
      pageSize,
      q: value,
      cat: activeKey,
    });
  };
  useEffect(() => {
    // 获取定位
    getAddress()
      .then((res) => {
        if (typeof res !== "string") {
          setAddress(res);
        }
      })
      .catch((e) => {
        console.log("获取定位失败", e);
      });

    if (!tabRef.current) {
      return;
    }
    // tab父元素绑定吸顶事件

    const onScroll = (e: Event) => {
      console.log("eeee", tabRef.current?.offsetTop);
      const elementOffsetTop = tabRef.current?.offsetTop || 0;
      const parentScrollTop = tabRef.current?.parentElement?.scrollTop || 0;

      setHasToTop(elementOffsetTop <= parentScrollTop);
    };
    tabRef.current.parentElement?.addEventListener("scroll", onScroll);
    return () => {
      tabRef.current?.parentElement?.removeEventListener("scroll", onScroll);
    };
  }, []);
  useEffect(() => {
    // 初始化类目
    const storeCategory = getStoreCategory();
    const cat = get(storeCategory, "0.key");
    setStoreCategory(storeCategory);
    if (cat) {
      setActiveKey(cat);
      getMaterialRun({
        pageNum: pageNo.current + 1,
        pageSize,
        cat,
      });
    }
  }, []);
  return (
    <Page>
      <PageItem className={styles.firstPageItem}>
        {address?.addressComponent?.city && (
          <div className={styles.address}>
            <Ellipsis content="杭州" />
          </div>
        )}
        <Search
          clearable
          onChange={setQueryStr}
          placeholder="纸巾"
          className={styles.input}
          onEnterPress={(e) => {
            const value = e.currentTarget.value;
            onSearch(value);
          }}
        />
      </PageItem>
      <PageItem className={styles.swiperPageItem}>
        <Swiper className={styles.swiper} autoplay loop>
          {items}
        </Swiper>
      </PageItem>
      <div
        className={classNames(styles.tabContainer, {
          [styles.tabToTop]: hasToTop,
        })}
        ref={tabRef}
      >
        <Tabs
          activeKey={activeKey}
          model="out"
          itemData={storeCategory}
          className={styles.tabs}
          onChange={changeTab}
          tabTitleClassName={styles.tabTitle}
          activeTitleClassName={styles.activeTabTitle}
        />
      </div>
      <PageItem className={styles.content}>
        {!!allMapData?.length && (
          <>
            <Grid columns={2} className={styles.productGrid}>
              {showData.map((item, index) => {
                return (
                  <Grid.Item key={index}>
                    <Grid columns={1}>
                      {item.map((li, iIndex: number) => {
                        return (
                          <Grid.Item
                            key={iIndex}
                            className={styles.productGridItem}
                          >
                            <StoreCard
                              coverUrl="https://gw.alicdn.com/imgextra/i2/O1CN01h8kpaG1RIoNETRdSH_!!6000000002089-2-tps-1053-420.png"
                              title="XXX饭店"
                              tag="85折"
                              onClick={() => {
                                window.location.href = "https://www.baidu.com";
                              }}
                            />
                          </Grid.Item>
                        );
                      })}
                    </Grid>
                  </Grid.Item>
                );
              })}
            </Grid>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </>
        )}
        {!materialLoading && !allMapData.length && (
          <div className={styles.empty}>暂无数据</div>
        )}
        {materialLoading && !allMapData.length && (
          <div className={styles.loading}>
            <SpinLoading />
          </div>
        )}
      </PageItem>
      <ToTop />
    </Page>
  );
};

export default ArriveStore;

import dd from "dingtalk-jsapi";
import { useEffect } from "react";

interface IPageTitleProps {
  /**
   * 页面标题
   */
  title?: string;
}
/**
 * 设置钉钉页面标题
 */
const usePageTitle = (data: IPageTitleProps) => {
  const { title } = data;
  useEffect(() => {
    try {
      dd.env.platform !== "notInDingTalk" &&
        dd.ready(() => {
          console.log(222);
          dd.biz.navigation.setTitle({
            title,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }, [title]);
  return {};
};
export default usePageTitle;

import React, { useCallback, useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { NumberKeyboard, PasscodeInput, Popup, Toast } from "antd-mobile";
import { regFenToYuan } from "src/common/Utils";
import { confirmPay } from "../../Services";

import { redirect } from "src/common/Utils";
import styles from "./index.module.scss";

const MAX_LENGTH = 6;
const TOAST_LOADING = {
  duration: 0,
  icon: "loading",
  content: "支付中…",
};

function PayPwd(props: any) {
  const { visible = false, onClose, data= {}, params = {} } = props;
  const { payAmount = 0, feeAmount = 0 } = data || {};
  const [values, setValues] = useState<number[]>([]);
  const currentFeeAmount = params?.feeAmount || feeAmount;
  const { run } = useRequest(confirmPay, {
    manual: true,
    onSuccess: (res: any) => {
      const { success = false, msg, data } = res || {};

      console.log(999, res);

      if (success) {
        onClose();
        Toast.clear();
        Toast.show({
          icon: "success",
          content: "支付成功",
          afterClose: () => {
            redirect(data);
            // redirect(`/payResult?payOrderId=${params.payOrderId}`);
          },
        });
        return;
      }

      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "支付失败，请重试!",
      });

      console.log(999, res);
    },
    onError: (err: any) => {
      const { msg } = err || {};
      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "支付失败，请重试!",
      });
      onClose();

      console.log("--pay-error--", err);
    },
  });

  useEffect(() => {
    if (visible) {
      setValues([]);
    }
  }, [visible]);

  const onInput = useCallback(
    (val: any) => {
      if (values.length >= MAX_LENGTH) {
        return;
      }

      setValues([...values, val]);
    },
    [values]
  );

  const onDelete = useCallback(() => {
    if (values.length <= 0) {
      return;
    }

    values.pop();
    setValues([...values]);
  }, [values]);

  const onFill = useCallback(
    (val: any) => {
      Toast.show(TOAST_LOADING);
      setTimeout(() => {
        run({ ...params, payPassword: val });
      }, 1000);
      console.log("---onFill--", val);
    },
    [values, params]
  );

  return (
    <Popup
      showCloseButton
      visible={visible}
      onClose={onClose}
      onMaskClick={onClose}
    >
      <ul className={styles.app}>
        <li className={styles.title}>请输入支付密码</li>
        <li className={styles.amount}>
          付款<span>{regFenToYuan(payAmount + currentFeeAmount)}</span>元
        </li>
        <li>
          <PasscodeInput seperated onFill={onFill} value={values.join("")} />
        </li>
        <li>
          <NumberKeyboard
            visible={visible}
            showCloseButton={false}
            onInput={onInput}
            onDelete={onDelete}
          />
        </li>
      </ul>
    </Popup>
  );
}

export default PayPwd;

import { useNavigate, NavigateOptions } from "react-router-dom";
import queryString from "query-string";
import { useCallback } from "react";

export interface IOpenUrlParams<T = any> {
  /**
   * 跳转链接
   */
  url: string;
  /**
   * 是否使用浏览器原生跳转,还是使用路由跳转,默认非路由跳转
   */
  isRoute?: boolean;
  /**
   * 跳转参数
   */
  params?: T;
  /**
   * 跳转参数, navigate的参数
   */
  options?: NavigateOptions;
}
export const useOpenUrl = () => {
  const navigate = useNavigate();
  const openUrl = useCallback(
    <T>({ url, isRoute = false, params, options }: IOpenUrlParams<T>) => {
      if (isRoute) {
        navigate(
          `${url}?${queryString.stringify(params || {}, { encode: true })}`,
          options
        );
      } else {
        window.location.href = url;
      }
    },
    [navigate]
  );
  return openUrl;
};

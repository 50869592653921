import React, { useCallback } from "react";
import { List, Checkbox, Popover } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";
import { regFenToYuan, getBankCardLastFour } from "src/common/Utils";
import styles from "./index.module.scss";

const FeeAmount = (props: any = {}) => {
  const { feeAmount } = props;

  if (!feeAmount || feeAmount === 0) {
    return null;
  }

  return (
    <div className={styles.list}>
      <div>
        <span>技术服务费</span>
        <Popover
          mode="dark"
          placement="bottom"
          content="什么是技术服务费、为什么收取服务费、在哪里开发票"
          trigger="click"
        >
          <QuestionCircleOutline fontSize={15} />
        </Popover>
      </div>
      <span>¥{regFenToYuan(feeAmount)}</span>
    </div>
  );
};

const ListItem = (props: any) => {
  const {
    onChange,
    checked,
    wayName,
    feeAmount = 0,
    payAmount = 0,
    cardNumber,
  } = props || {};
  return (
    <React.Fragment>
      <List.Item extra={<Checkbox onChange={onChange} checked={checked} />}>
        {wayName}({getBankCardLastFour(cardNumber)})
      </List.Item>
      {checked ? (
        <List.Item className={styles.detail}>
          <div className={styles.list}>
            <span>商品总价格</span>
            <span>¥{regFenToYuan(payAmount)}</span>
          </div>
          <FeeAmount feeAmount={feeAmount} />
        </List.Item>
      ) : null}
    </React.Fragment>
  );
};

function OneCard(props: any) {
  const { payInfo, onChangePayInfo, data = {}, orderInfo = {} } = props;
  const { payAmount = 0 } = orderInfo || {};
  const { methodName = '', bankCards = [], feeAmount = 0 } = data || {};
  const { payMethodId, bankCardId } = payInfo || {};

  const onClick = useCallback(() => {
    window.location.href = "/bankCardAdd?type=onePay";
  }, []);

  // if (!bankCards || bankCards.length === 0) {
  //   return null;
  // }

  const renderOpen = () => {
    if (bankCards.length === 0) {
      return (
        // <a href="/bankCardAdd?type=onePay">
          <List.Item clickable onClick={onClick}>
            开通芜湖本地生活{methodName}
          </List.Item>
        // </a>
      )
    }
  }

  return (
    <div className={styles.app}>
      <List>
        {renderOpen()}
        {
          bankCards.map((el: any) => {
            const { wayName, methodId, cardId, cardNumber } = el || {};
            const checked = payMethodId === methodId && bankCardId === cardId;

            return (
              <ListItem
                key={cardId}
                checked={checked}
                wayName={wayName}
                feeAmount={feeAmount}
                payAmount={payAmount}
                cardNumber={cardNumber}
                onChange={() =>
                  onChangePayInfo({
                    payMethodId: methodId,
                    bankCardId: cardId,
                    feeAmount: feeAmount,
                  })
                }
              />
            );
          })
        }
      </List>
    </div>
  );
}

export default OneCard;

import React, { useMemo } from "react";
import get from "lodash/get";
import styles from "./index.module.scss";
import { Ellipsis, Image, Space } from "antd-mobile";
import { MapDatum } from "../../service/getMaterial";

interface IProductCardProps {
  data: MapDatum;
  direction?: "vertical" | "horizontal";
}

/** 产品卡片  */
const ProductCard: React.FC<IProductCardProps> = (props) => {
  const { data, direction = "vertical" } = props;
  const money = useMemo(() => {
    const str = get(data, "price_promotion_info.zk_final_price", "");
    const moneyArr = str.split(".");
    return {
      // 小数点左边
      integer: get(moneyArr, "[0]", ""),
      // 小数点右边
      decimal: get(moneyArr, "[1]", ""),
    };
  }, [data]);
  const tagData = useMemo(() => {
    return get(
      data,
      "price_promotion_info.promotion_tag_list.promotion_tag_map_data"
    );
  }, [data]);
  if (direction === "horizontal") {
    return (
      <div
        className={styles.productHor}
        onClick={() => {
          if (data?.publish_info?.click_url) {
            window.location.href = data.publish_info.click_url;
          }
        }}
      >
        <Image
          className={styles.image}
          alt=""
          src={data.item_basic_info.pict_url}
          data-src={data.item_basic_info.white_image}
          fit="cover"
          lazy
        />
        <div className={styles.right}>
          <div className={styles.title}>
            <Ellipsis
              direction="end"
              rows={2}
              content={data.item_basic_info.title}
            />
          </div>
          <div className={styles.footer}>
            <div className={styles.info}>
              <div className={styles.price}>
                <span className={styles.unit}>¥</span>
                <span className={styles.amount}>{money.integer}</span>
                {money.decimal && (
                  <span className={styles.unit}>.{money.decimal}</span>
                )}
              </div>
              {data?.item_basic_info?.annual_vol && (
                <div className={styles.sales}>
                  <span className={styles.label}>已售</span>
                  {data.item_basic_info.annual_vol}
                </div>
              )}
            </div>
            {!!tagData?.length && (
              <Space className={styles.tags} wrap>
                {tagData?.map((item) => (
                  <div className={styles.tag}>{item.tag_name}</div>
                ))}
              </Space>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={styles.product}
      onClick={() => {
        if (data?.publish_info?.click_url) {
          window.location.href = data.publish_info.click_url;
        }
      }}
    >
      <Image
        className={styles.image}
        alt=""
        src={data.item_basic_info.pict_url}
        data-src={data.item_basic_info.white_image}
        fit="cover"
        lazy
      />
      <div className={styles.title}>
        <Ellipsis
          direction="end"
          rows={2}
          content={data.item_basic_info.title}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.price}>
          <span className={styles.unit}>¥</span>
          <span className={styles.amount}>{money.integer}</span>
          {money.decimal && (
            <span className={styles.unit}>.{money.decimal}</span>
          )}
        </div>
        <div className={styles.sales}>
          <span className={styles.label}>已售</span>
          {data.item_basic_info.annual_vol}
        </div>
      </div>
      <Space className={styles.tags} wrap>
        {tagData?.map((item, index) => (
          <div key={index} className={styles.tag}>
            {item.tag_name}
          </div>
        ))}
      </Space>
    </div>
  );
};

export default ProductCard;

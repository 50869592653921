import React, { useEffect } from "react";
import { Image } from "antd-mobile";

import styles from "./index.module.scss";

let lastFee = 0;

function NoCoupon() {
  
  return (
    <div className={styles.app}>
      <div>
        <Image className={styles.image} src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/nocoupon.png"></Image>
        <div className={styles.tips}>暂无红包</div>
      </div>
    </div>
  );
}

export default NoCoupon;

import React from "react";
// import { Button } from 'antd-mobile'
import Header from "./Component/Header";
import Content from "./Component/Content";
import styles from "./index.module.scss";

function InvoiceTitle() {
  return (
    <div className={styles.app}>
      <Header />
      <Content />
    </div>
  );
}

export default InvoiceTitle;

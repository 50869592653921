import React from "react";
import { useRequest } from "ahooks";
import Toolbar from "./Component/Toolbar";
// import Navigation from "./Component/Navigation";
import LiveList from "./Component/LiveList";
import { getDingTalkInfo } from "./Services";
import style from "./index.module.scss";
import NavigationSmall from "./Component/NavigationSmall";

function Home(props: any) {
  const { data, loading } = useRequest(getDingTalkInfo);
  
  return (
    <React.Fragment>
      <NavigationSmall />
      <div className={style.app}>
        <div className={style.banner}>
          <a href="/dailyCoupon">
            <img
              src="https://cdn.wuhuxianmai.cn/images/1724913582381.png"
              alt="消费券"
            />
          </a>
        </div>
        <Toolbar />
        <LiveList />
      </div>
    </React.Fragment>
  );
}

export default Home;

import React, { useState, useCallback, useRef, useEffect } from "react";
import { PullToRefresh } from "antd-mobile";
import { useRequest } from "ahooks";
import Header from "./Component/Header";
import Content from "./Component/Content";
import Filter from "./Component/Filter";
import Footer from "./Component/Footer";
import { getData } from "./Services";
import styles from "./index.module.scss";

function OrderHistory() {
  const pageRef = useRef(1);
  const paramRef = useRef<any>({});
  const [list, setList] = useState<any[]>([]);
  const [index, setIndex] = useState(1);
  const { loading, refresh, run } = useRequest(getData, {
    manual: true,
    onSuccess: (res: any) => {
      const { pages, records = [] } = res || {};

      pageRef.current = pages || 1;
      setList([...list, ...records]);
    },
  });

  useEffect(() => {
    run({ pageIndex: index });
  }, []);

  const onRefresh = async () => {
    setIndex(0);
    setList([]);

    await refresh();
  };

  const onSearch = useCallback(
    (params: any) => {
      const idx = 0;

      paramRef.current = params;

      setList([]);
      run({ pageIndex: idx, ...params });
      setIndex(idx);
    },
    [paramRef]
  );

  const onLoadData = useCallback(
    (idx: any) => {
      // setList([...list, ...val]);
      setIndex(idx);
      return run({ pageIndex: idx, ...paramRef.current });
    },
    [list, paramRef]
  );

  return (
    <PullToRefresh onRefresh={onRefresh}>
      <div className={styles.app}>
        <Header />
        <Filter onSearch={onSearch} />
        <Content
          loading={loading}
          index={index}
          data={list}
          totalPage={pageRef.current}
          onLoadData={onLoadData}
        />
        <Footer />
      </div>
    </PullToRefresh>
  );
}

export default OrderHistory;

import React, { useCallback } from "react";
import { List, Checkbox, Image } from "antd-mobile";
import Icon from "src/common/Component/Icon";
import { getBankCardLastFour } from "src/common/Utils";
import styles from "./index.module.scss";

function BankCard(props: any) {
  const { payInfo, onChangePayInfo, listStyle, data = {} } = props;
  const { methodName = '', bankCards = [], feeAmount = 0 } = data || {};
  const { payMethodId, bankCardId } = payInfo || {};
  const onClick = useCallback(() => {
    window.location.href = "/bankCardAdd?type=bankCard";
  }, []);

  // if (!data || !bankCards || total === 0) {
  //   return null;
  // }

  return (
    <div className={styles.app}>
      <List style={listStyle}>
        <List.Item
          prefix={<Icon icon="icon-yinhangka" className={styles.icon} />}
        >
          {methodName}
        </List.Item>

        {bankCards.map((el: any) => {
          const { wayName, cardNumber, methodId, cardId, wayCd } = el || {};
          const imageURL = `https://citycdn.oss-cn-hangzhou.aliyuncs.com/bank/${wayCd}_icon.png`

          return (
            <List.Item
              key={cardId}
              prefix={<Image src={imageURL} className={styles.icon} />}
              extra={
                <Checkbox
                  onChange={() =>
                    onChangePayInfo({
                      payMethodId: methodId,
                      bankCardId: cardId,
                      feeAmount: feeAmount,
                    })
                  }
                  checked={payMethodId === methodId && bankCardId === cardId}
                />
              }
            >
              {wayName}({getBankCardLastFour(cardNumber)})
            </List.Item>
          );
        })}
        <List.Item className={styles.add} clickable onClick={onClick}>
          添加{methodName}
        </List.Item>
      </List>
    </div>
  );
}

export default BankCard;

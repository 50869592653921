import { text } from "stream/consumers";

const data = [{
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082dbnddgigu05g5nkpdvig9djdu4t60f0!nc_n_webp_mw_1.webp',
    text: '烟台海昌渔人码头 | 我仿佛来到了欧洲'
}, {
    text: '上山玩了一天',
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082dbdlsm8go00048rbfg20sq3mqautroo!nc_n_webp_mw_1.webp',
}, {
    text: '不来一趟香港🇭🇰你都不知道有多出片！！',
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082d1tfpoah20005omcttgggaa1ufvrpb8!nc_n_webp_mw_1.webp'
}, {
    text: '公主的烦心事永远打烊',
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082davebq8gm0004a1409gbvst2758lmb8!nc_n_webp_mw_1.webp'
}, {
    text: '🍃在大鹏有一处隐秘的婚纱摄影基地，掩映在树林中，不起眼的路牌，一不小心就会错过。摸进藏在树林里的小路，踏过淙淙流水的小桥，一条清澈的小溪就映入眼帘。树影婆娑，斑驳的光影，美得仿佛世外桃源。',
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082cqdc89qh001g496ejs07kmtspcr4rm8!nc_n_webp_mw_1.webp'
}, {
    text: '威海4.12景区通航情况！！！！！',
    url: 'https://cdn.wuhuxianmai.cn/images/1000g0082b1cmac6h80005o7bjl50bnh65kod5co!nc_n_webp_mw_1.webp'
}];



export default data;
import React from "react";
import { Checkbox, Button } from "antd-mobile";
import styles from "./index.module.scss";

function Footer(props: any) {
  //   const { title, content, serviceFee, date, amount } = props;
  return (
    <div className={styles.app}>
      <Checkbox>全选</Checkbox>
      <div className={styles.actions}>
        <span className={styles.text}>合计：¥</span>
        <span className={styles.amount}>28.00</span>
        <Button shape="rounded" size="small" color="primary">
          去开票
        </Button>
      </div>
    </div>
  );
}

export default Footer;

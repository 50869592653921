import apiClient from "src/common/Utils/APIClient";

interface IFavoritesReq {}
/**
 * 收藏列表-获取所有收藏夹id
 */
export const getFavoritesId = (params: IFavoritesReq): Promise<Data> => {
  return apiClient.get<Data>("/api/admin/tbk/favorites", { param: params });
};
export interface Data {
  tbk_dg_material_recommend_response: TbkDgMaterialRecommendResponse;
}

export interface TbkDgMaterialRecommendResponse {
  is_default: string;
  result_list: ResultList;
  request_id: string;
}

export interface ResultList {
  map_data: MapDatum[];
}

export interface MapDatum {
  favorites_info: FavoritesInfo;
  item_basic_info: ItemBasicInfo;
  presale_info: Info;
  price_promotion_info: Info;
  publish_info: PublishInfo;
  scope_info: Info;
}

export interface FavoritesInfo {
  favorites_list: FavoritesList;
  total_count: number;
}

export interface FavoritesList {
  favorites_detail: FavoritesDetail[];
}

export interface FavoritesDetail {
  favorites_id: number;
  favorites_title: string;
}

export interface ItemBasicInfo {
  volume: number;
}

export interface Info {}

export interface PublishInfo {
  daily_promotion_sales: number;
  income_info: Info;
  two_hour_promotion_sales: number;
}

import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const getCardOrders = (params: any) => {
  const { cardId = "" } = params || {};
  return apiClient
    .get("/api/admin/buyerPay/BuyerBankCard/detail", {
      param: {
        cardId,
      },
    })
    .then((res: any) => {
      const { data = {} } = res || {};
      return data;
    });
};

const deleteBankCard = (params: any) => {
  const { cardId = "" } = params || {};
  return apiClient
    .post("/api/admin/buyerPay/BuyerBankCard/delete", { cardId })
    .then((res: any) => {
      const { data = {} } = res || {};

      console.log(555, res);
      return data;
    });
};

export { getCardOrders, deleteBankCard };

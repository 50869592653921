import React from "react";
import Icon from "../../../../common/Component/Icon";
import data from "./data";
import style from "./index.module.scss";

const Item: React.FC<any> = (props = {}) => {
  const { url, text } = props;

  return (
    <ul className={style.item}>
      <li>
        <img src={url} alt="tips" />
      </li>
      <li className={style.font}>{text}</li>
    </ul>
  );
};

function LiveList() {
  return (
    <div className={style.live}>
      <div className={style.header}>
        <Icon icon="icon-star" className={style.icon} />
        <span>惠生活</span>
      </div>
      <div className={style.content}>
        {data.map((el) => {
          const { url, text } = el || {};
          return <Item key={url} url={url} text={text} />;
        })}
      </div>
    </div>
  );
}

export default LiveList;

import React, { useEffect, useMemo } from "react";
import { Empty, Image, InfiniteScroll, Skeleton, Space } from "antd-mobile";
import get from "lodash/get";
import { useRequest } from "ahooks";

import { useConfigJSON } from "src/hooks/useConfigJSON";
import Page from "src/common/Component/Page";
import ToTop from "src/common/Component/ToTop";
import usePageTitle from "src/hooks/usePageTitle";
import { getFavoritesList } from "../../service/getFavoritesList";
import ProductCard from "../../components/ProductCard";
import { IConfig } from "./config";
import styles from "./index.module.scss";

interface IActivityProps {}
const options = { manual: true, searchParamsName: ["id"] };

/** 活动详情页  */
const Activity: React.FC<IActivityProps> = (props) => {
  const {} = props;
  const { config } = useConfigJSON<IConfig>({
    options,
  });
  const {
    data: favortesListData,
    run: favortesListRun,
    loading,
  } = useRequest(getFavoritesList, {
    manual: true,
  });
  usePageTitle({ title: "活动详情页" });
  async function loadMore() {
    // const append = await mockRequest();
    // setData((val) => [...val, ...append]);
    // setHasMore(append.length > 0);
  }

  useEffect(() => {
    if (!config?.activityId) return;
    favortesListRun({
      favoriteId: config?.activityId,
    });
  }, [config]);
  const showList = useMemo(() => {
    const list = get(
      favortesListData,
      "tbk_dg_material_recommend_response.result_list.map_data"
    );
    return list;
  }, [favortesListData]);

  return (
    <Page className={styles.page}>
      <Image
        src={config?.banner?.imageUrl}
        alt=""
        fit="cover"
        className={styles.banner}
      />
      <div className={styles.content}>
        {config?.title && <div className={styles.title}>{config?.title}</div>}
        {!loading && !showList?.length && <Empty description="暂无数据" />}
        {loading && !showList?.length && (
          <Skeleton.Paragraph lineCount={20} animated />
        )}

        {!!showList?.length && (
          <Space direction="vertical" block className={styles.productList}>
            {showList?.map((item, index) => {
              return (
                <ProductCard direction="horizontal" data={item} key={index} />
              );
            })}
            <InfiniteScroll loadMore={loadMore} hasMore={false} />
          </Space>
        )}
      </div>
      <ToTop />
    </Page>
  );
};

export default Activity;

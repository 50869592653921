import React from "react";
// import { Toast } from "antd-mobile";
// import { redirect } from "src/common/Utils";
import { STATIC_HOST } from "src/common/Config/Constant";
import styles from "./index.module.scss";

function NavigationSmall(props: any) {
  return (
    <div className={styles.nav}>
      <div className={styles.icon}>
        <img src={`${STATIC_HOST}/logo1.png`} alt="" />
        <span>芜湖本地生活</span>
      </div>
      <div className={styles.my}>
        <span>我的</span>
        <a href="/wallet">
          <img src={`${STATIC_HOST}/b17617a869c03ca5.png`} alt="" />
        </a>
        <a href="/coupon">
          <img
            className={styles.coupon}
            src={`${STATIC_HOST}/31fc7e6921a4ca21.png`}
            alt=""
          />
        </a>
      </div>
    </div>
  );
}

export default NavigationSmall;

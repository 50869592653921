import React from "react";
import styles from "./index.module.scss";
import { Ellipsis, Image } from "antd-mobile";
interface IStoreCardProps {
  /**封面图*/
  coverUrl?: string;
  /**点击事件*/
  onClick?: () => void;
  /**标题*/
  title?: string;
  /**折扣标签*/
  tag?: string;
}

/** 商店卡片  */
const StoreCard: React.FC<IStoreCardProps> = (props) => {
  const { coverUrl, onClick, tag, title } = props;

  return (
    <div className={styles.storeCard} onClick={onClick}>
      <div className={styles.storeImg}>
        <Image
          src={coverUrl}
          fit="cover"
          lazy
          alt=""
          className={styles.coverImage}
        />
        {tag && <div className={styles.tag}>{tag}</div>}
      </div>
      <div className={styles.storeInfo}>
        <Ellipsis content={title || ""} rows={2} />
      </div>
    </div>
  );
};

export default StoreCard;

import React from "react";
import "./index.css";

interface IIconProps {
  icon?: string;
  className?: string;
}
function Icon(props: IIconProps = {}) {
  const { icon, className = "" } = props;
  return (
    <svg className={`icon ${className}`} aria-hidden="true">
      <use xlinkHref={`#${icon}`}></use>
    </svg>
  );
}

export default Icon;

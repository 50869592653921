import React, { useEffect } from "react";
import * as dd from "dingtalk-jsapi";
import Filter from "./Component/Filter";
import Content from "./Component/Content";

import NoCoupon from "./Component/NoCoupon";
import style from './index.module.scss';

function Coupon() {

  useEffect(() => {
    try {
      dd.env.platform !== 'notInDingTalk' && 
      dd.ready(() => {
        console.log(222)
        dd.biz.navigation.setTitle({
          title: '优惠券'
        });
      });
    } catch(e) {
      console.log(e)
    }
    
  }, []);

  return (
    <div className={style.app}>
      {/* <Filter />
      <Content /> */}
      <NoCoupon />
    </div>
  );
}



export default Coupon;
import React from "react";
import InvoiceItem from "./Component/InvoiceItem";
import styles from "./index.module.scss";

const data = [
  {
    date: "2024-08-26 16:03",
    title: "浙江先迈数字科技有限公司",
    amount: "28.00",
    status: "done",
  },
  {
    date: "2024-08-26 16:03",
    title: "浙江先迈数字科技有限公司",
    amount: "28.00",
    status: "padding",
  },
];

function InvoiceRecord() {
  return (
    <div className={styles.app}>
      {data.map((el: any, idx: any) => {
        return <InvoiceItem key={idx} {...el} />;
      })}
    </div>
  );
}

export default InvoiceRecord;

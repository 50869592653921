import AMapLoader from "@amap/amap-jsapi-loader";

export interface IAddressResponse {
  addressComponent: {
    cityCode: string;
    adCode: string;
    businessAreas?: any[];
    neighborhoodType: "";
    neighborhood: "";
    building: "";
    buildingType: "";
    street: "";
    streetNumber: "";
    country: "中国";
    /** 省份 */
    province: "";
    /** 城市 */
    city: "";
    /** 区县 */
    district: "";
    towncode: "";
    /** 小区/街道 */
    township: "";
  };
  /**  详细地址 * */
  formattedAddress: string;
  roads: any[];
  crossed: any[];
  pois: any[];
}
/**
 * 获取当前位置
 */
export const getAddress = (): Promise<IAddressResponse | string> => {
  window._AMapSecurityConfig = {
    securityJsCode: "9a60c60cc55e5db176b37d82f9048f34",
  };

  return new Promise((resolve, reject) => {
    AMapLoader.load({
      key: "fb80b58dbb2eca4ccd6907ff78b0a974", //申请好的 Web 端开发者 Key，首次调用 load 时必填
      version: "2.0", //指定要加载的 JS API 的版本，缺省时默认为 1.4.15
      plugins: ["AMap.Geolocation", "AMap.Geocoder"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['AMap.Scale','...','...']
    })
      .then((AMap) => {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 10000, // 设置定位超时时间，默认：无穷大
          offset: [10, 20], // 定位按钮的停靠位置的偏移量
          zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          position: "RB", //  定位按钮的排放位置,  RB表示右下
        });

        geolocation.getCurrentPosition(function (status: any, result: any) {
          if (status === "error") {
            reject(new Error("获取地址失败"));
            return;
          }
          if (status === "complete") {
            const geocoder = new AMap.Geocoder();
            geocoder.getAddress(
              [result.position.lng, result.position.lat],
              (status: string, result: any) => {
                console.log(status, result);
                if (status === "complete" && result.info === "OK") {
                  resolve(result.regeocode);
                } else {
                  reject(new Error("获取地址失败"));
                }
              }
            );
          }
        });
      })
      .catch((e) => {
        console.log(e, 111);
      });
  });
};

import React from "react";
import { BANK_HOST } from "src/common/Config/Constant";
import styles from "./index.module.scss";

function CustomCard(props: any = {}) {
  const { wayCd = "", title = "", cardNumber = "", className = '' , style = {} } = props;

  return (
    <div className={`${styles.app} ${className || ''}`} style={{ ...style, backgroundImage: `url('${BANK_HOST}/${wayCd}_bg.png')`  }}>
      <div className={styles.header}>
        <div className={styles.icon}>
        <img src={`${BANK_HOST}/${wayCd}_icon.png`} alt="卡片" />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.content}>{cardNumber}</div>
    </div>
  );
}

export default CustomCard;

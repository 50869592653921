import React, { useState, useEffect } from "react";
import { InfiniteScroll, Empty } from "antd-mobile";
// import { mockRequest } from "../../data";
import Order from "../Order";
import PageLoading from "src/common/Component/PageLoading";

// import { CheckCircleFill } from "antd-mobile-icons";
import styles from "./index.module.scss";

function Content(props: any) {
  const { index, data = [], onLoadData, loading, totalPage } = props;
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(index < totalPage);
  }, [index, totalPage]);

  const loadMore = async () => {
    if (loading) {
      return;
    }
    const nextIndex = index + 1;

    if (nextIndex > totalPage) {
      setHasMore(false);
      return Promise.resolve();
    }

    return onLoadData(nextIndex);
  };

  if (loading) {
    return <PageLoading />;
  }

  if (!data || data.length <= 0) {
    return (
      <div className={styles.app}>
        <Empty description="暂无数据" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.app}>
        {data.map((el: any, idx: any) => {
          return <Order key={idx} {...el} />;
        })}
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </>
  );
}

export default Content;

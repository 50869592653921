import React from "react";
import { SpinLoading } from "antd-mobile";
import styles from "./index.module.scss";

function PageLoading(props: any) {
  return (
    <div className={styles.loading}>
      <SpinLoading color="default" style={{ "--size": "32px" }} />
      <div>Loading....</div>
    </div>
  );
}

export default PageLoading;

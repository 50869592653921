import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";

import { useEffect, useMemo } from "react";
import { getConfigDetail } from "src/pages/Mall/service/getConfigDetail";

interface IUseConfigJSON {
  params?: {
    id?: number | string;
    keycode?: string;
  };
  options?: {
    manual?: boolean;
    /**
     * 配置项参数名
     */
    searchParamsName?: string[];
  };
}
export const useConfigJSON = <T>(data: IUseConfigJSON) => {
  const { options, params } = data;
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: configData,
    loading,
    run: getConfigDetailRun,
  } = useRequest(getConfigDetail, {
    defaultParams: [params],
    manual: options?.manual,
  });
  const config: T | undefined = useMemo(() => {
    try {
      const configObj = JSON.parse(configData?.value || "{}");
      return configObj;
    } catch (error) {
      return undefined;
    }
  }, [configData]);
  useEffect(() => {
    if (!options?.searchParamsName?.length && !options?.manual) {
      return;
    }
    const params: any = {};
    options?.searchParamsName?.forEach((item) => {
      params.id = searchParams.get(item);
    });
    console.log("params", typeof params.id);
    getConfigDetailRun(params);
  }, []);
  return {
    config,
    configData,
    loading,
    run: getConfigDetailRun,
  };
};

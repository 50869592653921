import React, { useCallback, useState } from "react";
import styles from "./index.module.scss";

const data = [
  {
    key: 0,
    text: "饿了么",
  },
  {
    key: 1,
    text: "飞猪超市",
  },
  {
    key: 2,
    text: "全能超市",
  },
];

const Item: React.FC<any> = (props: any) => {
  const { text, activate = false, onClick, index } = props;
  const className = activate ? styles.activate : "";

  return (
    <div
      onClick={() => onClick(index)}
      className={`${styles.item} ${className}`}
    >
      {text}
    </div>
  );
};

function Nav() {
  const [index, setIndex] = useState(0);

  const onClick = useCallback((i: any) => {
    setIndex(i);
  }, []);

  return (
    <div className={styles.app}>
      {data.map((el: any, idx) => {
        const { key, text } = el;
        return (
          <Item
            onClick={onClick}
            key={key}
            index={idx}
            text={text}
            activate={index === idx}
          />
        );
      })}
    </div>
  );
}

export default Nav;

/**
 * 商店类目
 */
const storeCategory = [
  {
    key: "50011977",
    title: "全部",
  },
  {
    key: "16",
    title: "小吃快餐",
  },
  {
    key: "50011978",
    title: "烧烤烤串",
  },

  {
    key: "50011986",
    title: "面包甜点",
  },
  {
    key: "50011990",
    title: "食品滋补",
  },
];
/**
 * 获取类目
 */
export const getStoreCategory = () => {
  return storeCategory;
};

/**
 * banner数据
 */
export const bannerList = [
  {
    linkUrl: "https://www.baidu.com",
    imgUrl:
      "https://gw.alicdn.com/imgextra/i2/O1CN01h8kpaG1RIoNETRdSH_!!6000000002089-2-tps-1053-420.png",
  },
  {
    linkUrl: "https://www.baidu.com",
    imgUrl:
      "https://gw.alicdn.com/imgextra/i2/O1CN01h8kpaG1RIoNETRdSH_!!6000000002089-2-tps-1053-420.png",
  },
  {
    linkUrl: "https://www.baidu.com",
    imgUrl:
      "https://gw.alicdn.com/imgextra/i2/O1CN01h8kpaG1RIoNETRdSH_!!6000000002089-2-tps-1053-420.png",
  },
  {
    linkUrl: "https://www.baidu.com",
    imgUrl:
      "https://gw.alicdn.com/imgextra/i2/O1CN01h8kpaG1RIoNETRdSH_!!6000000002089-2-tps-1053-420.png",
  },
];

import React, { useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { Ellipsis, Grid, Image, Skeleton, Space } from "antd-mobile";
import chunk from "lodash/chunk";
import { useRequest } from "ahooks";
import { getFavoritesList } from "../../service/getFavoritesList";
import get from "lodash/get";
import { formatNumToMoney, splitFavoriteTitle } from "src/common/Utils";
import { IOpenUrlParams, useOpenUrl } from "src/hooks/useOpenUrl";
interface IProductListItem {
  /** 图片地址 */
  imageUrl: string;

  /** 价格 */
  price: string;

  link?: IOpenUrlParams;
}
interface IFeaturedCardsProps {
  /** 标题 */
  title?: string;
  /** 更多链接 */
  moreLink?: IOpenUrlParams;
  /** 自定义数据 */
  productList?: IProductListItem[];
  /** 是否自定义 */
  hasCustomize?: boolean;
  /** 收藏夹ID */
  favoritesId?: number;
}

/** 精选-卡片  */
const FeaturedCards: React.FC<IFeaturedCardsProps> = (props) => {
  const {
    title,
    moreLink,
    productList = [],
    favoritesId,
    hasCustomize,
  } = props;
  const { data: favoriteListData, run: favoriteListDtaRun } = useRequest(
    getFavoritesList,
    {
      manual: true,
    }
  );
  const openUrl = useOpenUrl();

  useEffect(() => {
    if (!favoritesId) return;
    favoriteListDtaRun({
      favoriteId: favoritesId,
    });
  }, [favoritesId]);
  const showList = useMemo(() => {
    if (hasCustomize) {
      return chunk(productList, 2);
    }
    const mapData = get(
      favoriteListData,
      "tbk_dg_material_recommend_response.result_list.map_data",
      []
    );
    /** 获取商品列表,取前两个 */
    const data: IProductListItem[] = mapData
      ?.map((item) => {
        return {
          imageUrl: item.item_basic_info.pict_url,
          price:
            item.price_promotion_info.zk_final_price ||
            item.price_promotion_info.reserve_price,
          link: moreLink || {
            url: item.publish_info.click_url,
          },
        };
      })
      .slice(0, 2);
    return chunk(data, 2);
  }, [hasCustomize, favoriteListData, productList, moreLink]);
  return (
    <Space direction="vertical" block className={styles.space}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Ellipsis content={splitFavoriteTitle(title || "").title}></Ellipsis>
        </div>
        {moreLink && (
          <div
            className={styles.more}
            onClick={() => {
              if (moreLink) {
                openUrl({
                  url: moreLink.url,
                  isRoute: moreLink.isRoute,
                  params: moreLink.params,
                });
              }
            }}
          >
            更多
          </div>
        )}
      </div>
      {showList?.map((item, index) => {
        return (
          <Grid key={index} columns={2} className={styles.productGrid}>
            {item.map((li, iIndex: number) => {
              const money = formatNumToMoney(li.price);
              return (
                <Grid.Item
                  key={iIndex}
                  onClick={() => {
                    if (li.link) {
                      // a1;
                      openUrl({
                        url: li.link.url,
                        isRoute: li.link.isRoute,
                        params: li.link.params,
                        options: li.link.options,
                      });
                    }
                  }}
                >
                  <Image
                    className={styles.productImg}
                    alt=""
                    src={li.imageUrl}
                    fit="cover"
                    lazy
                  />
                  <div className={styles.price}>
                    <span className={styles.unit}>￥</span>
                    <span className={styles.num}>{money.num}</span>
                    <span className={styles.unit}>{money.unit}</span>
                  </div>
                </Grid.Item>
              );
            })}
          </Grid>
        );
      })}
      {!showList.length && <Skeleton.Paragraph lineCount={3} animated />}
    </Space>
  );
};

export default FeaturedCards;

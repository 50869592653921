import React, { useEffect } from "react";
import CountUp from 'react-countup';
import { regFenToYuan } from "src/common/Utils";
import styles from "./index.module.scss";

let lastFee = 0;

function Header(props: any) {
  const { data, payInfo } = props || {};
  const { payAmount = 0, feeAmount = 0 } = data || {};
  const currentFeeAmount = payInfo?.feeAmount || feeAmount;
  const currentFee = payAmount + currentFeeAmount;

  useEffect(() => {
    lastFee = currentFee;
  }, [currentFee]);
  
  return (
    <ul className={styles.header}>
      <li className={styles.sub}>实付金额</li>
      <li className={styles.amount}>
        ¥<CountUp
          className={styles.count}
          start={regFenToYuan(lastFee)}
          end={regFenToYuan(currentFee)}
          duration={0.8}
          decimals={2}
        ></CountUp>
      </li>
    </ul>
  );
}

export default Header;

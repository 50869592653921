import apiClient from "src/common/Utils/APIClient";

interface IMaterialReq {
  pageSize: number;
  pageNum: number;
  /**
   * 搜索的关键词
   */
  q?: string;
  /**
   * 商品筛选-后台类目ID。用,分割，最大10个
   */
  cat?: string;
  /**
   * 物料id
   */
  materialId?: number;
  /**
   * 投放位id
   */
  zoneId?: number;
}
/**
 * 获取商品列表--淘宝客-推广者-物料搜索升级版
 */
export const getMaterial = (params: IMaterialReq): Promise<Data> => {
  // 截掉
  const cat = params.cat?.split(",").slice(0, 10).join(",");
  return apiClient
    .post("/api/admin/tbk/item", { ...params, cat })
    .then((res: Data) => {
      return res;
    });
};
export interface Data {
  tbk_dg_material_optional_upgrade_response: TbkDgMaterialOptionalUpgradeResponse;
}

export interface TbkDgMaterialOptionalUpgradeResponse {
  /**
   * 搜索到符合条件的结果总数
   */
  total_results: number;
  result_list: ResultList;
  /**
   * uvid结果信息，传入但未使用uvid时会返回原因
   */
  uvid_msg: string;
}

export interface ResultList {
  map_data: MapDatum[];
}

export interface MapDatum {
  /**
   * 商品信息-淘宝客新商品id
   */
  item_id: string;
  /**
   * 淘客推广信息
   */
  publish_info?: PublishInfo;
  /**
   * 价格促销信息
   */
  price_promotion_info: PricePromotionInfo;
  /**
   * 商品基础信息
   */
  item_basic_info: ItemBasicInfo;
  /**
   * 天猫榜单信息
   */
  tmall_rank_info: TmallRankInfo;
  /**
   * 预售信息
   */
  presale_info: PresaleInfo;
  /**
   * 商品库范围信息

   */
  scope_info: ScopeInfo;
  /**
   * 线报内容
   */
  mgc_info?: MgcInfo;
  /**
   * 物料评估-匹配分
   */
  match_score?: string;
  /**
   * 物料评估-收益分
   */
  commi_score?: string;
}

export interface ItemBasicInfo {
  /**
   * 商品信息-商品标题
   */
  title: string;
  /**
   * 商品信息-商品短标题

   */
  short_title: string;
  /**
   * 商品信息-商品主图

   */
  pict_url: string;
  /**
   * 商品信息-商品白底图

   */
  white_image: string;
  /**
   * 商品信息-一级类目ID

   */
  level_one_category_id: number;
  /**
   * 商品信息-叶子类目id

   */
  category_id: number;
  /**
   * 商品信息-叶子类目名称

   */
  category_name: string;
  /**
   * 店铺信息-卖家id

   */
  seller_id: number;
  /**
   * 店铺信息-卖家类型，0表示淘宝，1表示天猫，3表示特价版
   */
  user_type: number;
  /**
   * 店铺信息-店铺名称

   */
  shop_title: string;
  /**
   * 商品信息-30天销量；数据统计截止昨日非实时更新
   */
  volume: number;
  /**
   * 商品信息-商品子标题
   */
  sub_title: string;
  /**
   * 商品信息-品牌名称
   */
  brand_name: string;
  /**
   * 商品信息-一级类目名称
   */
  level_one_category_name: string;
  /**
   * 商品信息-淘客30天推广量

   */
  tk_total_sales?: string;
  /**
   * 商品信息-宝贝所在地

   */
  provcity?: string;
  /**
   * 商品信息-商品小图列表
   */
  small_images?: string[];
  /**
   * 年销量

   */
  annual_vol: string;
  /**
   * 商品邮费

   */
  real_post_fee: string;
}

export interface MgcInfo {
  /**
   * 价格
   */
  price: string;
  /**
   * 价格描述
   */
  price_desc: string;
  /**
   * 文案

   */
  promotion_summary: string;
  /**
   * 发布时间，13位毫秒时间戳
   */
  publish_time: string;
  /**
   * 生效时间，实时线报为0，未来线报为13位毫秒时间戳
   */
  valid_time: string;
}

export interface PresaleInfo {
  /**
   * 预售商品-付定金开始时间（毫秒）
   */
  presale_start_time: number;
  /**
   * 预售商品-付定金结束时间（毫秒）
   */
  presale_end_time: number;
  /**
   * 预售商品-付尾款开始时间（毫秒）
   */
  presale_tail_start_time: number;
  /**
   * 预售商品-付尾款结束时间（毫秒）
   */
  presale_tail_end_time: number;
  /**
   * 预售商品-定金（元）
   */
  presale_deposit: string;
  /**
   * 预售商品-优惠信息
   */
  presale_discount_fee_text: string;
}

export interface PricePromotionInfo {
  /**
   * 促销信息-预估凑单价（元）。预估凑单叠加优惠后的商品单价
   */
  predict_rounding_up_price: string;
  /**
   * 促销信息-凑单价说明，描述凑单价的实现说明。如 “可凑单”或“需买X件”
   */
  predict_rounding_up_price_desc: string;
  /**
   * 更多活动优惠
   */
  more_promotion_list: MorePromotionList;
  /**
   * 商品信息-一口价通常显示为划线价
   */
  reserve_price: string;
  /**
   * 促销信息-销售价格，无促销时等于一口价，有促销时为促销价。若属于预售商品，付定金时间内，在线售卖价=预售价
   */
  zk_final_price: string;
  /**
   * 促销信息-预估到手价(元)。若属于预售商品，付定金时间内，预估到手价价=定金+尾款的预估到手价
   */
  final_promotion_price: string;
  /**
   * 到手价优惠路径列表
   */
  final_promotion_path_list: FinalPromotionPathList;
  /**
   * 预热预估到手价（元）
   */
  future_activity_promotion_price: string;
  /**
   * 预热到手价优惠路径列表
   */
  future_activity_promotion_path_list: FutureActivityPromotionPathList;
  /**
   * 标签信息列表
   */
  promotion_tag_list: PromotionTagList;
}

export interface FinalPromotionPathList {
  final_promotion_path_map_data: FinalPromotionPathMapDatumElement[];
}

export interface FinalPromotionPathMapDatumElement {
  /**
   * 优惠名称，如“商品券”、“跨店满减”、“单品直降”等
   */
  promotion_title: string;
  /**
   * 优惠利益点文案，如“1件7.92折”、“每200减20”等
   */
  promotion_desc: string;
  /**
   * 实际优惠金额（元）
   */
  promotion_fee?: string;
  /**
   * 优惠开始时间
   */
  promotion_start_time: string;
  /**
   * 优惠结束时间

   */
  promotion_end_time: string;
  /**
   * 优惠ID
   */
  promotion_id?: string;
}

export interface FutureActivityPromotionPathList {
  future_activity_promotion_path_map_data: FinalPromotionPathMapDatumElement[];
}

export interface MorePromotionList {
  more_promotion_map_data: FinalPromotionPathMapDatumElement[];
}

export interface PromotionTagList {
  promotion_tag_map_data: PromotionTagMapDatum[];
}

export interface PromotionTagMapDatum {
  /**
   * 标签名称，如“88VIP”、“花呗免息”、“猫超买返”、“是否包邮”
   */
  tag_name: string;
}

export interface PublishInfo {
  /**
   * 商品信息-收入比率(%)；商品佣金比率+补贴比率
   */
  income_rate: string;
  /**
   * 前N件佣金信息-前N件佣金生效或预热时透出以下字段
   */
  topn_info?: TopnInfo;
  /**
   * 链接-宝贝推广链接
   */
  click_url: string;
  /**
   * 链接-宝贝+券二合一页面链接
   */
  coupon_share_url: string;
  /**
   * 预热活动到手价对应的佣金比率
   */
  future_activity_commission_rate: string;
  /**
   * 预热价活动开始时间
   */
  future_activity_time: string;
  /**
   * 定向计划集合-仅支持联系商务或运营小二申请定向计划合集字段权限
   */
  sp_campaign_list: SPCampaignList;
  /**
   * 榜单url
   */
  rank_page_url?: string;
  /**
   * 推广信息-商品信息-佣金类型。MKT表示营销计划，SP表示定向计划，COMMON表示通用计划，ZX表示自选计划
   */
  commission_type?: string;
  /**
   * 商品佣金信息
   */
  income_info: IncomeInfo;
  /**
   * 额外奖励活动类型，如果一个商品有多个奖励类型，返回结果使用空格分割，0=预售单单奖励，1=618超级U选单单补
   */
  cpa_reward_type: string;
  /**
   * 额外奖励活动金额，活动奖励金额的类型与cpa_reward_type字段对应，如果一个商品有多个奖励类型，返回结果使用空格分割
   */
  cpa_reward_amount: string;
  /**
   * 商品是否包含定向计划
   */
  include_dxjh: string;
  /**
   * 两小时推广销量。 非实时，约半小时更新
   */
  two_hour_promotion_sales: number;
  /**
   * 当天推广销量。 非实时，约1小时更新

   */
  daily_promotion_sales: number;
}

export interface IncomeInfo {
  /**
   * 商品佣金比率
   */
  commission_rate: string;
  /**
   * 商品佣金金额

   */
  commission_amount: string;
  /**
   * 补贴比率

   */
  subsidy_rate: string;
  /**
   * 补贴金额

   */
  subsidy_amount: string;
  /**
   * 补贴上限；仅在单笔订单命中补贴上限时返回结果否则出参为空

   */
  subsidy_upper_limit: string;
  /**
   * 补贴类型

   */
  subsidy_type: string;
}

export interface SPCampaignList {
  sp_campaign: SPCampaign[];
}

export interface SPCampaign {
  /**
   * 定向计划活动ID
   */
  sp_cid: string;
  /**
   * 定向计划名称
   */
  sp_name: string;
  /**
   * 定向佣金率
   */
  sp_rate: string;
  /**
   * 定向是否锁佣，0=不锁佣 1=锁佣
   */
  sp_lock_status: string;
  /**
   * 定向计划申请链接
   */
  sp_apply_link: string;
  /**
   * 定向计划是否可用 1-可用 0-不可用
   */
  sp_status: string;
}

export interface TopnInfo {
  /**
   * 前N件剩余库存
   */
  topn_quantity: number;
  /**
   * 前N件初始总库存
   */
  topn_total_count: number;
  /**
   * 前N件佣金结束时间
   */
  topn_end_time: string;
  /**
   * 前N件佣金开始时间
   */
  topn_start_time: string;
  /**
   * 前N件佣金率
   */
  topn_rate: string;
}

export interface ScopeInfo {
  /**
   * 是否品牌精选，0不是，1是
   */
  superior_brand: string;
}

export interface TmallRankInfo {
  /**
   * 榜单排行描述
   */
  tmall_rank_text: string;
  /**
   * 榜单url

   */
  tmall_rank_url: string;
}
